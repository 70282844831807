// Dependencies
import React, { useContext } from "react";
import { graphql, Link } from "gatsby";
// Hooks & Helpers
import useShare from "../hooks/useShare";
import useMicrocopy from "../hooks/useMicrocopy";
// Context
import { MusicContext } from "../context/MusicContext";
// Components
import { PlayButton } from "../components/MusicPlayer";

const Track = ({ track, playlist, showActions, microcopy }) => {

  // Context
  const [activeTrack] = useContext(MusicContext);
  const isActiveTrack = activeTrack && (activeTrack.contentful_id === track.contentful_id);
  const shareUrl = `https://mscty.space/track/${track.slug}`;

  // Content
  const subtitle = track.artists ? track.artists[0].name : track.subtitle;
	const { aboutTheArtist, viewProject, shareTrack } = microcopy

  // UI
  const { share, hasShared } = useShare({ url: shareUrl });

  return (
    <div className={`track ${isActiveTrack ? 'is-playing' : ''}`}>
      <div className="track__contents">
        <div className="track__button">
          <PlayButton track={track} playlist={playlist} />
          <div className="info">
            {track.title} <br />
            <strong>{subtitle}</strong>
          </div>
        </div>
        {track.locationRadius && (
          <h6 className="">
            {track.location.lat} <br />
            {track.location.lon}
          </h6>
        )}

      </div>

      {(showActions || isActiveTrack) && (
        <div className="track__actions">
          {track.projectLink && (
            <Link to={track.projectLink.category ? `/${track.projectLink.category.slug}/${track.projectLink.slug}` : `/${track.projectLink.slug}`} className="btn">{viewProject}</Link>
          )}
					{track.artists && (
						<Link to={`/artist/${track.artists[0].slug}`} className="btn">{aboutTheArtist}</Link>
					)}
          <button className="btn" onClick={share}>{hasShared || shareTrack}</button>
        </div>
      )}
    </div>
  )
}

// const PlaylistShareButton = ({ share, hasShared }) => (
//   <button className="btn" onClick={share}>{hasShared || 'Share Playlist'}</button>
// )

const TrackList = ({ slug, tracks, locale }) => {

	const { aboutTheArtist, viewProject, shareTrack } = useMicrocopy(locale)

  return (
    <div>
      {tracks.map((track, index) => (
        <Track 
					track={track} 
					microcopy={{ aboutTheArtist, viewProject, shareTrack }}
					playlist={{ slug, tracks }} 
					showActions={tracks.length === 1} 
					key={`track-${index}`} 
				/>
      ))}
    </div>
  )
}

export default TrackList

export const ContentfulTrackFragment = graphql`
  fragment ContentfulTrackFragment on ContentfulTrack {
		__typename
    contentful_id
    title
    slug
    subtitle
    artists {
      name
			slug
    }
    mp3 {
      file {
        url
      }
    }
    mp3Preview {
      file {
        url
      }
    }
    artwork {
      ...ContentfulArtworkFragment
    }
    copy {
      raw
    }
    vimeoUrl
    projectLink {
      slug
      category {
        slug
      }
      cover {
        ...ContentfulArtworkFragment
      }
      thumbnail {
        ...ContentfulArtworkFragment
      }
      titles {
        raw
      }
      caption {
        raw
      }
    }
    location {
      lat
      lon
    }
    locationRadius
  }
`
