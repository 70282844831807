import { useState, useEffect } from 'react';
import copyToClipboard from 'copy-to-clipboard'; // NOTE

export default function useShare(shareData) {
  const [hasShared, setShared] = useState(false);

  useEffect(() => {
    if (hasShared) {
      const timeoutId = setTimeout(() => setShared(false), 5000);
      return () => clearTimeout(timeoutId);
    }
    return;
  }, [hasShared])

  const share = async () => {
    const fallbackCopyText = shareData.url || shareData.text || shareData.title || '';
		// eslint-disable-next-line
    const isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
    const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    try {
      if (!iOS && isSafari) {
        const hasCopied = copyToClipboard(fallbackCopyText);
        setShared(hasCopied ? "Link copied to clipboard!" : false);
      } else {
        await navigator.share(shareData)
        setShared("Shared!")
      }
    } catch(err) {
      if (!(err.message.includes('cancel'))) {
        const hasCopied = copyToClipboard(fallbackCopyText);
        setShared(hasCopied ? "Link copied to clipboard!" : false);
      } else {
        setShared(false);
      }
    }
  }

  return { share, hasShared };
}
